<template>
  <div style="height: 100%">
    <div class="palyerContent">
      <div id="player" style="height: 250px"></div>
    </div>
    <el-scrollbar style="height: calc(100vh - 250px)">
      <div class="cont mytree" style="">
        <el-tree
          :indent="15"
          default-expand-all
          highlight-current
          :expand-on-click-node="true"
          :data="coursePackage"
          :props="defaultProps"
         
          @node-click="handleNodeClick"
        >
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span v-if="data.VideoId != null && data.VideoId != ''">
              <i class="bf"></i>
              {{ data.Duration | sec_to_time }}
            </span>
          </div>
        </el-tree>
      </div>
    </el-scrollbar>
  </div>
</template>
<script>
import {
  courseQueryPackageDetail,
  courseQueryCourseDetail,
  courseQueryCourseMenu,
} from "@/api/course";
import { liveQueryVideoToken } from "@/api/personal";
export default {
  data() {
    return {
      vodPlayerJs: "https://player.polyv.net/resp/vod-player/latest/player.js",
      coursePackage: [],
      course: [],
      defaultProps: {
        label: "MenuName",
        children: "children",
      },
      videoOption: {
        vid: "",
      },
    };
  },

  mounted() {
    var queryParam = this.$route.query;
    console.log(queryParam);
    if (queryParam.courseId == null) {
      this.$message.error("参数传递错误");
      return false;
    }
    this.loadPlayerScript(this.loadPlayer);
    this.getcourseQueryCourseDetail(queryParam.courseId);
    // this.getCourseQueryCourseMenu(59);
  },
  methods: {
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>{node.level}{node.label}</span>
          <span>
             {this.sec_to_time(data.Duration)}
          </span>
        </span>
      );
    },
    //获取课程包详情
    async getcourseQueryCourseDetail(id) {
      const res = await courseQueryCourseDetail(id);
      if (res.success === true) {
        this.course = res.response;
        if (this.course.IsCharge == true) {
          this.$message.error("当前页面无法观看收费课程，请去APP上观看");
          return false;
        }
        // coursePackage 课程包（0不是1是）
        if (this.course.CoursePackage === 1) {
          this.postCourseQueryPackageDetail(this.course.Id);
        } else {
          this.coursePackage = [res.response];
          for (let i = 0; i < this.coursePackage.length; i++) {
            this.$set(
              this.coursePackage[i],
              "MenuName",
              this.coursePackage[i].Name
            );
            this.$set(this.coursePackage[i], "children", []);
            this.getCourseQueryCourseMenu(this.coursePackage[i].Id, i);
          }
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    //获取课程包详情
    async postCourseQueryPackageDetail(id) {
      const res = await courseQueryPackageDetail(id);
      if (res.success === true) {
        this.coursePackage = res.response;

        for (let i = 0; i < this.coursePackage.length; i++) {
          this.$set(
            this.coursePackage[i],
            "MenuName",
            this.coursePackage[i].Name
          );
          this.$set(this.coursePackage[i], "children", []);
          this.getCourseQueryCourseMenu(this.coursePackage[i].Id, i);
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    async getCourseQueryCourseMenu(id, i) {
      const res = await courseQueryCourseMenu(id);
      if (res.success === true) {
        let obj = res.response?.data ? res.response?.data : [];
        // console.log(obj, '')
        if (obj.length > 0) {
          let arr = obj.filter((lis) => {
            if (lis.ParentId == 0) {
              this.$set(lis, "children", []);
              return lis;
            }
          });

          this.coursePackage[i].children = arr;
          this.getPackage(this.coursePackage, obj);
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    getPackage(arr, obj) {
      if (arr !== undefined) {
        arr.forEach((item, i) => {
          obj.forEach((it, j) => {
            if (item.Id === it.ParentId) {
              if (item.children === undefined) {
                this.$set(item, "children", []);
              }
              item.children.push(it);
            }
          });
          this.getPackage(item.children, obj);
        });
      }
    },
    handleNodeClick(data, node) {
      if (data.VideoId != null && data.VideoId != "") {
        this.videoOption.vid = data.VideoId;
        // this.player.changeVid(this.videoOption);
        this.getLiveQueryVideoToken();
      } else {
        this.$toast("该目录没有点播课程");
        return;
      }
    },
    //点播解密
    async getLiveQueryVideoToken() {
      const res = await liveQueryVideoToken(this.videoOption.vid);
      if (res.success == true) {
        console.log(res);
        this.playsafe = res.response.token;
        this.ts = res.response.createdTime;
        this.sign = res.response.sign;
        //切换视频
        this.videoOption.vid = this.videoOption.vid;
        this.videoOption.playsafe = this.playsafe;
        this.videoOption.ts = this.ts;
        this.videoOption.sign = this.sign;

        this.player.changeVid(this.videoOption);
      }
    },
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },

    loadPlayer() {
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: "#player",
        width: "100%",
        height: "250",
        vid: this.videoOption.vid,
      });
    },
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>
<style lang="less" scoped>
html,
body {
  height: 100%;
}
#app {
  height: 100%;
}
.palyerContent {
  height: 100%;
}
/deep/.el-tree-node__content {
  min-height: 40px;
  border-bottom: 1px solid #e6e9ed;
  // padding-left: 0 !important;
}
/deep/.el-tree {
  /deep/.el-tree-node__content {
    height: 30px !important;
    // padding-left: 0 !important;
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 8px;
    .bf {
      display: inline-block;
      width: 16px;
      height: 14px;
      background-size: 100% 100%;
      background-image: url("../../../assets/images/play.png");
      margin-right: 10px;
    }
  }
}
</style>